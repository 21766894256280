import * as Yup from 'yup'





export const HeroValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    button_text: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
    image: Yup.mixed().notRequired().nullable().when("image", {
        is: true,
        then: Yup.mixed().test("fileSize", "Ukuran image terlalu besar", (value) => {
            return value && value.size >= 1500000
        })
    }),

}, [
    // Add Cyclic deps here because when require itself
    ['image', 'image'],
]);

export const AboutValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    button_text: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
    image: Yup.mixed().notRequired().nullable().when("image", {
        is: true,
        then: Yup.mixed().test("fileSize", "Ukuran image terlalu besar", (value) => {
            return value && value.size >= 1500000
        })
    }),

}, [
    // Add Cyclic deps here because when require itself
    ['image', 'image'],
]);

export const PergerakanValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    subData: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            subtitle: Yup.string().required('Required'),
        })
    ).required('Required')
});

export const AppValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
}, [
    // Add Cyclic deps here because when require itself
    ['image', 'image'],
]);
export const LayananValidationSchema = Yup.object().shape({
    mainData: Yup.array().of(
        Yup.object().shape({
            image: Yup.mixed().notRequired().nullable().when("image", {
                is: true,
                then: Yup.mixed().test("fileSize", "Ukuran image terlalu besar", (value) => {
                    return value && value.size >= 1500000
                })
            }),
            title: Yup.string().required('Required'),
            subtitle: Yup.string().required('Required'),
            button_text: Yup.string().required('Required'),
            link: Yup.string().required('Required'),
        }, [
            // Add Cyclic deps here because when require itself
            ['image', 'image'],
        ])
    ).required('Required'),
});
export const InformationValidationSchema = Yup.object().shape({
    mainData: Yup.array().of(
        Yup.object().shape({
            image: Yup.mixed().notRequired().nullable().when("image", {
                is: true,
                then: Yup.mixed().test("fileSize", "Ukuran image terlalu besar", (value) => {
                    return value && value.size >= 1500000
                })
            }),
            title: Yup.string().required('Required'),
            subtitle: Yup.string().required('Required'),
        }, [
            // Add Cyclic deps here because when require itself
            ['image', 'image'],
        ])
    ).required('Required'),
});


export const KarirValidationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    button_text: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
    image: Yup.mixed().notRequired().nullable().when("image", {
        is: true,
        then: Yup.mixed().test("fileSize", "Ukuran image terlalu besar", (value) => {
            return value && value.size >= 1500000
        })
    }),

}, [
    // Add Cyclic deps here because when require itself
    ['image', 'image'],
]);